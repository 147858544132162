
import { gql } from '@apollo/client';

export const GET_HELP = gql`
  query HelpQuery {
    allPageHelpdesks(orderBy: ID_ASC) {
      nodes {
        id
        name
        description
        pageHelpdeskAttachmentsByHelpdeskId {
          nodes {
            pageAttachmentByAttachmentId {
              name
              file
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_HELP_DETAIL = gql`
query HelpQuery($id: BigInt = "") {
  allPageHelpdesks(orderBy: ID_ASC, condition: {id: $id}) {
    nodes {
      id
      name
      description
      pageHelpdeskAttachmentsByHelpdeskId {
        nodes {
          pageAttachmentByAttachmentId {
            name
            file
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_NEWS = gql`
  query NewsQuery($categoryId: BigInt!) {
    allPageEntries(condition: {visible: true, categoryId: $categoryId}, orderBy: DATE_DESC) {
      nodes {
        id
        name
        nameEn
        date
        description
        descriptionEn
        visible
        partnerId
        pageCategoryByCategoryId {
          id
          name
        }
        pageEntryAttachmentsByEntryId {
          nodes {
            pageAttachmentByAttachmentId {
              file
              name
            }
          }
        }
        pageEntryPhotosByEntryId {
          nodes {
            pagePhotoByPhotoId {
              name
              mainImage
              file
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_NEWS_DETAIL = gql`
query NewsQuery($id: BigInt = "22") {
  allPageEntries(condition: {visible: true, id: $id}, orderBy: DATE_DESC) {
    nodes {
      id
      name
      date
      description
      visible
      partnerId
      pageCategoryByCategoryId {
        id
        name
      }
      pageEntryAttachmentsByEntryId {
        nodes {
          pageAttachmentByAttachmentId {
            file
            name
          }
        }
      }
      pageEntryPhotosByEntryId {
        nodes {
          pagePhotoByPhotoId {
            name
            mainImage
            file
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_TUTORIAL = gql`
query TutorialQuery {
  allPageMovies(orderBy: NAME_ASC) {
    nodes {
      id
      name
      url
    }
    totalCount
  }
}
`;

export const GET_USERS_COUNT = gql`
query UsersCountQuery {
  allAccountsUsers {
    totalCount
  }
}
`;

export const GET_CITIES_COUNT = gql`
query CitiesCountQuery($greaterThanOrEqualTo: Date = "") {
  allPagePartners(
    filter: {contractValidTo: {greaterThanOrEqualTo: $greaterThanOrEqualTo}}
  ) {
    totalCount
  }
}
`;

export const GET_FOR_PARTNER = gql`
query ForPartner ($id: BigInt = "") {
  pageForpartnerById(id: $id) {
    name
    description
  }
}
`;

export const GET_DISCRITS = gql`
query GetDiscrits {
  allActivitiesVoivodeships {
    nodes {
      name
    }
  }
}
`;

export const GET_PARTNER_CITY = gql`
query GetPartnerCity($greaterThan: Date = "") {
  allPagePartners(
    condition: {visible: true}
    filter: {contractValidTo: {greaterThan: $greaterThan}}
  ) {
    nodes {
      id
      name
      logo
      description
      contractValidTo
      cityGroupId
      activitiesCitygroupByCityGroupId {
        activitiesVoivodeshipByVoivodeshipId {
          name
          id
          activitiesCitygroupsByVoivodeshipId {
            totalCount
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_COMPETITIONS = gql`
query GetCompetitions {
  allActivitiesCompetitions {
    totalCount
  }
}
`;

