import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import News from './components/NewsSection';
import Function from './components/FunctionSection';
import Help from './components/HelpSection';
import Footer from './components/Footer';
import NewsDetail from './components/NewsDetail';
import HelpDetail from './components/HelpDetail';
import TutorialVideos from './components/TutorialVideosSection';
import Map from './components/MapSection';
import Samorzad from './components/SamorzadSection';
import Biznes from './components/BiznesSection';
import PrivacyPolicy from './components/PrivacyPolicy';
import RegulaminyRywalizacji from './components/RegulaminyRywalizacji';
import RegulaminAplikacji from './components/RegulaminAplikacji';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import './i18n';
import { useTranslation } from 'react-i18next';
import './App.css';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

function App() {

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [highContrast, setHighContrast] = useState(false);

  function changeLanguage(lng) {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  }

  function toggleHighContrast() {
    setHighContrast(prevState => !prevState);
  }


  
  return (
    <div className={`App ${highContrast ? 'high-contrast' : ''}`}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path="/function" element={<Function />} />
          <Route path="/help" element={<Help />} />
          <Route path="/help/:id" element={<HelpDetail />} />
          <Route path="/tutorial" element={<TutorialVideos />} />
          <Route path="/map" element={<Map />} />
          <Route path="/samorzad" element={<Samorzad />} />
          <Route path="/biznes" element={<Biznes />} />
          <Route path="/privacy-policy/:categoryId" element={<PrivacyPolicy />} />
          <Route path="/regulaminy-rywalizacji" element={<RegulaminyRywalizacji />} />
          <Route path="/regulamin-aplikacji/:categoryId" element={<RegulaminAplikacji />} />
        </Routes>
        <Footer />

        <CookieConsent
          debug={false}
          location="bottom"
          buttonText="Rozumiem"
          expires={365}
          style={{
            background: "white",
            color: "black",
            boxShadow: "0px -10px 25px rgba(0, 0, 0, 0.2)",
            minHeight: "200px",
            lineHeight: "1.5",
          }}
          buttonStyle={{
            color: "white",
            fontSize: "15px",
            padding: "15px",
            borderRadius: "25px",
            backgroundColor: "#006027",
          }}
        >
          <h2 style={{color: '#006027'}}>Uwaga! Ten serwis wykorzystuje pliki cookies.</h2>
          <p>Zapoznaj się z informacją o <Link to="/privacy-policy/5">zasadach zachowania prywatności użytkowników</Link>. Jeśli nie chcesz, by pliki cookies były zapisywane na Twoim dysku <a href="http://ciasteczka.eu/#jak-wylaczyc-ciasteczka">zmień ustawienia swojej przeglądarki</a>.<br></br>
          Zamknięcie tego komunikatu oznacza zgodę na zapisywanie plików cookies na Twoim komputerze.</p>
        </CookieConsent>
      </Router>

      <div className="floating-buttons3">
        <button className={`cotrast-button ${highContrast ? 'active' : ''}`}  style={{ cursor: 'pointer' }} onClick={toggleHighContrast}>{t('function_section.kontrast')}</button>
      </div>
      <div className="floating-buttons2">
        <button className={`language-button ${currentLanguage === 'pl' ? 'selected' : ''}`} onClick={() => changeLanguage('pl')}>PL</button>
        <button className={`language-button ${currentLanguage === 'en' ? 'selected' : ''}`} onClick={() => changeLanguage('en')}>EN</button>
      </div>
      <div className="floating-buttons">
        <div>
          <button className="round-button" style={{ cursor: 'pointer' }} onClick={() => window.location.href='https://www.facebook.com/aktywnemiastapl/'}>
              <FontAwesomeIcon icon={faFacebookF} size="2x"/>
          </button>
          <button className="round-button" style={{ cursor: 'pointer' }} onClick={() => window.location.href='https://www.youtube.com/channel/UCg5q4GLYXbtl6Y37v7MYHGQ'}>
              <FontAwesomeIcon icon={faYoutube} size="2x" />
          </button>
          <button className="round-button" style={{ cursor: 'pointer' }} onClick={() => window.location.href='https://www.instagram.com/aktywnemiasta.pl/'}>
              <FontAwesomeIcon icon={faInstagram} size="2x" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
