import React from 'react';
import './NewsSection.css';
import { useNavigate } from 'react-router-dom';
import '../i18n';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { marked } from 'marked';

function formatDate(isoDate) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = new Date(isoDate).toLocaleDateString('pl-PL', options);
    return formattedDate;
}

function NewsItem({ id, name, date, description, pageEntryPhotosByEntryId, pageEntryAttachmentsByEntryId }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const baseUrl = "https://aktywne.miasta.pl/media/";

    const mainImage = pageEntryPhotosByEntryId.nodes.find(node => node.pagePhotoByPhotoId.mainImage);
    const imageUrl = mainImage ? baseUrl + mainImage.pagePhotoByPhotoId.file : null;

    const handleClick = () => {
        navigate(`/news/${id}`, {
            state: {
                name,
                date,
                description,
                imageUrl: imageUrl,
                attachments: pageEntryAttachmentsByEntryId.nodes
            }
        });
    }

    const dom = new DOMParser().parseFromString(description, 'text/html');
    const modifiedDescription = dom.body.innerHTML;
    const html = marked.parse(modifiedDescription);

    return (
        <div className="news-item">
            <img src={imageUrl} alt={name} />
            <h3>{name}</h3>
            <p className="date">{formatDate(date)}</p>
            <p>{parse(html)}</p>
            <div className="news-item-button-container">
                <button onClick={handleClick}>{t('news_section_item.WIĘCEJ')} &gt;</button>
            </div>
        </div>
    );
}

export default NewsItem;
